
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import Card from '@/components/shared/Card.vue';
import Header from '@/components/shared/Header.vue';
import { getPolicyText } from '@/services/api/ApiMne';

export default defineComponent ({
  components: { Card, IonPage, IonContent, IonButton, Header },
  setup() {
    const state = reactive({
      policyText: {}
    })

    onMounted (async () => {
      state.policyText = await getPolicyText();
    })

    return {
      ...toRefs(state),
    }
  }
});

